$primaryColor: #4caf50;

.rhap_container  {
    height: 0;
    position: absolute;
}

html, body, #root {
    height: 100%;
    overflow-y: auto;
    background: #fff;
}

.MuiSwitch-colorSecondary.Mui-checked {
    color: $primaryColor !important;
}

.MuiSwitch-colorSecondary.Mui-checked + .MuiSwitch-track {
    background-color: #c2e39d !important;
}

p {
    font-size: 1rem;
}

.right-centerInResponsive {
    text-align: right;
}

.aboutBlock {
    margin: 3% auto 0 !important;
    text-align: center;
    h1 {
        font-size: 2.5rem;
        color: $primaryColor;
        font-weight: 500;
    }
    p {
        font-size: 1.3rem;
        line-height: 1.9rem;
        margin-bottom: 30px;
        font-weight: 300;
    }
}

.generalFooter {
    padding: 30px 0;
}

.MuiSelect-selectMenu{
    padding-bottom:  13px !important;
}
.mb-40{
    margin-bottom: 40px !important;
}
.mb-11 {
    margin-bottom: 10px !important;
}

.banner{
    text-align: center;
    padding: 20px 0 20px 0;
    background: #c4f5c6;
    font-size: 18px;
    font-weight: bolder;
    color: #666;
    box-shadow: 1px 0px 4px #ddd;
    display: block;
    z-index: 1;
    position: relative;
    line-height: 16px;
    span{
        font-weight: bold;
        color: #111;
    }
    svg{
        float: right;
        margin-top: -5px;
        margin-right: 10px;
        margin-left: 10px;
        cursor: pointer;
    }
} 
.db-none{
    display: none
}   

@media (max-width: 1199px) {
    .left-centerInResponsive,
    .right-centerInResponsive {
        text-align: center;
    }
}


@media (max-width: 767px) {
    .left-centerInResponsive,
    .right-centerInResponsive {
        text-align: center;
    }
    .aboutBlock {
        margin: 0 15px !important;
    }
    .banner{
        font-size: 14px;
        padding: 10px 0 10px 0;
    }
}

